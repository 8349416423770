import React from 'react';
import Link from 'gatsby-link';

import pageLinks from '../content/pageLinks';

const NavBar = props => (
  <nav className="nav-wrapper">
    <ul className="nav-links">
      {pageLinks.map(page => (
        <li className="nav-link-wrapper" key={`${page.title}-link`}>
          { page.externalPage ? 
            <a href={page.route}>{page.title}</a> : 
            <Link to={`/${page.route}`} activeClassName="active-link">{page.title}</Link> 
          }
        </li>
      ))}
    </ul>
  </nav>
);

export default NavBar;
