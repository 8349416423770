const pageLinks = [
  {
    title: 'Music',
    route: 'music',
  },
  {
    title: 'Shows',
    route: 'shows',
  },
  {
    title: 'Videos',
    route: 'videos',
  },
  {
    title: 'Social',
    route: 'social',
  },
  {
    title: 'Contact',
    route: 'contact'
  },
  {
    title: "Merch",
    route: "https://townesmusic.bandcamp.com/merch",
    externalPage: true
  }
];

export default pageLinks;
