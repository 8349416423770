import React from 'react';
import Layout from '../components/Layout';

const Admin = () => (
  <Layout>
    <div>
        <p>Hello</p>
    </div>
  </Layout>
);

export default Admin;