import React from 'react';

import "./styles/footer.css";

const Footer = ({ siteTitle }) => (
  <div className="footer-wrapper">
    <p>Website by Kurt van Bendegem</p>
  </div>
);

export default Footer;
