import React from 'react';
import Link from 'gatsby-link';

import NavBar from './Navbar';

import "./styles/header.css";

const Header = ({ siteTitle }) => (
  <div className="header-wrapper">
    <div className="header-content">
      <h1 className="site-title">
        <Link
          to="/"
          style={{
            textDecoration: 'none',
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
    <NavBar />
  </div>
);

export default Header;
