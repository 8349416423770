import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Header from './Header'
import Footer from './Footer'

import './styles/base.css'
import './styles/index.css'

import favicon32 from '../img/favicon.png'

const Layout = ({ children }) => (
  <div className="site-wrapper">
    <Helmet
      title={"Townes"}
      meta={[
        { name: 'description', content: 'Townes Music' },
      ]}
      link={[
        { rel: 'shortcut icon', type: 'image/png', href: `${favicon32}` },
      ]}
    />
    <Header siteTitle={"Townes"} />
    <div className="page-wrapper">
      {children}
      <Footer/>
    </div>
  </div>
)

Layout.propTypes = {
  children: PropTypes.func,
}

export default Layout

export const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
